import React, { useState, useEffect } from "react";
import { STORY_PAGE, TOKEN_KEY } from "../config/Constants";
import Loading from "./Loading";
import { setAuthToken, axiosInstance } from "./AxiosInstance";
import { Container, Row, Col, Card, Collapse } from 'react-bootstrap';
import './style/StoryPage.css'; // Import custom CSS for additional styling

const StoryPage = ({ setErrorMsg, activePage }) => {
  const [storyData, setStoryData] = useState(null);
  const [storyLoading, setStoryLoading] = useState(true);
  const [openStory, setOpenStory] = useState(null); // To track the open story
  setAuthToken(localStorage.getItem(TOKEN_KEY));

  const fetchData = async () => {
    try {
      const storyResp = await axiosInstance.get(STORY_PAGE, { params: { page: activePage } });
      if (storyResp.status === 200) {
        setStoryData(storyResp.data);
      } else {
        setErrorMsg(storyResp.data);
      }
    } catch (error) {
      if (error.response.status === 403) {
        setErrorMsg("You don't have access to this page");
      } else if (error.response.status === 423) {
        setErrorMsg(error.response.data);
      } else {
        setErrorMsg("Failed to load the page");
      }
    } finally {
      setStoryLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activePage]);

  if (storyLoading) return <Loading />;

  const handleToggleStory = (index) => {
    setOpenStory(openStory === index ? null : index); // Toggle the open story
  };

  return (
    <Container fluid className="page-container">
      {storyData && storyData.map((story, index) => (
        <Row key={index} className="story-row mb-4">
          <Col md={12}>
            <Card className="story-card">
              <Card.Header
                onClick={() => handleToggleStory(index)}
                aria-controls={`story-collapse-${index}`}
                aria-expanded={openStory === index}
                className="story-heading"
              >
                <h2 style={{ cursor: "pointer", margin: 0 }}>{story.heading}</h2>
              </Card.Header>
              <Collapse in={openStory === index}>
                <Card.Body id={`story-collapse-${index}`}>
                  <Row className="mt-3">
                    {/* Images block */}
                    <Col md={12} className="story-images">
                    <Row>
                    {story.videoImageClubbedMap && 
                        Object.entries(story.videoImageClubbedMap).map(([key, assetType], index) => {
                          if (assetType === 'VIDEO') { // Only render for videos
                            return (
                              <video
                                key={index}
                                src={key} 
                                autoPlay
                                muted 
                                loop
                                controls
                                style={{ width: '100%', height: 'auto' }}
                              >
                                Your browser does not support the video tag.
                              </video>
                            );
                          } else if (assetType === 'IMAGE') { // Render for images
                            return (
                              <img
                                key={index}
                                src={key}
                                alt={`Image ${key}`} // Provide alt text for accessibility
                                style={{ width: '100%', height: 'auto' }}
                              />
                            );
                          } 
                          return null; // Return null for non-video and non-image types
                        })
                      }
                    </Row>
                    </Col>
                    {/* Story Asset button */}
                    <Col md={12} className="mt-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => window.location.href = story.assetUrl}
                      >
                        Story Asset
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Collapse>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default StoryPage;