import { setAuthToken, axiosInstance } from '../components/AxiosInstance';
import {STORY_IMAGE_API, TOKEN_KEY } from './Constants';

export const fetchImageUrl = async (imageId) => {
    try {
        setAuthToken(localStorage.getItem(TOKEN_KEY));
        const imageResp = await axiosInstance.get(STORY_IMAGE_API, {
            params: { imageId },
            responseType: 'arraybuffer'
        });
        if (imageResp.status === 200) {
            const contentDisposition = imageResp.headers['content-disposition'];
            let fileName = '';
            if(contentDisposition.includes('attachment;filename=')){
                fileName = contentDisposition.replace('attachment;filename="', '');
                fileName = fileName.replace('"', '');
            }
            // Convert ArrayBuffer to a base64 string
            const base64String = arrayBufferToBase64(imageResp.data);
            const contentType = imageResp.headers['content-type'];
            return {'fileName': fileName, 'data': `data:${contentType};base64,${base64String}`};
        }
    } catch (error) {
    }
    return null; // Return null if fetching image fails
};

// Helper function to convert ArrayBuffer to Base64
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};