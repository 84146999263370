import React from 'react';
import './style/Loading.css';

const Loading = ({viewBox}) => {
    if(viewBox == null){
        viewBox = '0 0 240 240';
    }
    return (
        <div style={{width: '100vw', height: '100vh', textAlign: 'center', padding: '10vh 10vw'}}>
            <div style={{ width: '100%', height: '100%' }}>
                <svg className="pl" viewBox={viewBox}>
                    <circle className="pl__ring pl__ring--a" cx="120" cy="120" r="105" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 660" strokeDashoffset="-330" strokeLinecap="round"></circle>
                    <circle className="pl__ring pl__ring--b" cx="120" cy="120" r="35" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 220" strokeDashoffset="-110" strokeLinecap="round"></circle>
                    <circle className="pl__ring pl__ring--c" cx="85" cy="120" r="70" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 440" strokeDashoffset="round"></circle>
                    <circle className="pl__ring pl__ring--d" cx="155" cy="120" r="70" fill="none" stroke="#000" strokeWidth="20" strokeDasharray="0 440" strokeDashoffset="round"></circle>
                </svg>
            </div>
        </div>
    );
};

export default Loading;