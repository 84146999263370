import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { LOGIN_API, TOKEN_KEY } from "../config/Constants";
import axios from "axios";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchWebsiteLogo } from "../config/ImageUtils";
import { Col, Row } from "react-bootstrap";

const Login = ({ setIsLoggedIn, setErrorMsg, logoImageSrc }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (username && password) {
      try {
        const postData = { username: username, password: password };
        const loginResponse = await axios.post(LOGIN_API, postData);
        if (loginResponse.status === 200) {
          setErrorMsg(null);
          localStorage.setItem(TOKEN_KEY, loginResponse.data);
          setIsLoggedIn(true);
        } else {
          setErrorMsg(loginResponse.data);
        }
      } catch (error) {
        if (error.response.status === 423) {
          setErrorMsg(error.response.data);
        } else {
          setErrorMsg("Failed to login user, please use correct username and password");
        }
      }
    }
  };

  return (
      <div className="page-container display-grid" >
      <div className="max-content" >
        <div className="text-center mb-4">
          <img src={logoImageSrc} alt="logo" className="img-fluid" style={{ maxWidth: "150px" }} />
        </div>
        <h2 className="text-center mb-3">Welcome back!</h2>
        <p className="text-center mb-4">Please enter your details</p>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="username">Username</label>
            <input
              type="email"
              className="form-control"
              id="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password">Password</label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="input-group-append">
                <span className="input-group-text h-100" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Link to="/forgot-password" className="text-decoration-none">
              Forgot password?
            </Link>
          </div>
          <Row className="text-center">
            <Col md={6} sm={6} ><button type="submit" className="btn btn-success btn-block w-100">Log In</button></Col>
            <Col md={6} sm={6} ><Link to="/register" className="btn btn-danger btn-block w-100">Sign Up</Link></Col>
          </Row>
        </form>
      </div>
      </div>
  );
};

export default Login;
