import React, { useState, useEffect } from 'react';
import './App.css'; // Assuming you have CSS for styling
import Notification from './components/Notification';
import { IS_SERVER_UP, CURRENT_USER, TOKEN_KEY, CHECK_TOKEN_EXIST_INTERVAL} from './config/Constants';
import axios from 'axios';
import MaintenancePage from './components/MaintenancePage';
import Loading from './components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import StoryPage from './components/StoryPage';
import NavBar from './components/NavBar';
import { axiosInstance, setAuthToken } from './components/AxiosInstance';
import Register from './components/Register';
import AdminPage from './components/AdminPage';
import ForgotPassword from './components/ForgotPassword';
import ControllerApp from './components/ControllerApp';
import Login from './components/Login';
import Message from './components/Message';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [serverError, setServerError] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [activePage, setActivePage] = useState(null);
    const [logoImageSrc, setLogoImageSrc] = useState(null);
    var GET_WEBSITE_BACKGROUND_API;

    setInterval(() => {
        if(localStorage.getItem(TOKEN_KEY) == null){
            setIsLoggedIn(false);
        }
    }, CHECK_TOKEN_EXIST_INTERVAL);

    const fetchData = async () => {
        try {
            const resp = await axios.get(IS_SERVER_UP);
            if(resp.status === 200){
                GET_WEBSITE_BACKGROUND_API = resp.data.websiteBackgroundImage;
                setLogoImageSrc(resp.data.websiteLogo);
                document.documentElement.style.setProperty('--website-bg-link', `url("${GET_WEBSITE_BACKGROUND_API}")`);
            }
        } catch (error) {
            setServerError(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try{
                setAuthToken(localStorage.getItem(TOKEN_KEY));
                const currentUserResp = await axiosInstance.get(CURRENT_USER);
                if(currentUserResp.status === 200){
                    setErrorMsg(null);
                    setUser(currentUserResp.data);
                    setIsLoggedIn(true);
                } else {
                    setErrorMsg(currentUserResp.data);
                    setUser(null);
                    setIsLoggedIn(false);
                    setActivePage(null);
                }
            } catch(error){
                setErrorMsg(error.data);
                setUser(null);
                setIsLoggedIn(false);
                setActivePage(null);
            }
        }
        fetchUser();
    }, [isLoggedIn]);

    if (loading) return <Loading />;
    if (serverError) return <MaintenancePage />;

    const renderElement = (Component, props) => {
        return isLoggedIn ? <Component {...props} /> : <Login setIsLoggedIn={setIsLoggedIn} setErrorMsg={setErrorMsg} logoImageSrc={logoImageSrc} />;
    };
  
    return (
        <Router>
            <div className="App">
                <NavBar activePage={activePage} updateActivePage={setActivePage} user={user} setIsLoggedIn={setIsLoggedIn} logoImageSrc={logoImageSrc}/>
                <Notification errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
                <Routes>
                    <Route path="/" element={renderElement(StoryPage, { setErrorMsg, activePage })} />
                    <Route path="/admin/*" element={renderElement(AdminPage, { setErrorMsg })} />
                    <Route path="/controllerApp" element={renderElement(ControllerApp, { setErrorMsg, activePage })} />
                    <Route path="/messages" element={renderElement(Message, { setErrorMsg, activePage })} />
                    <Route path="/register" element={<Register setErrorMsg={setErrorMsg} />} />
                    <Route path="/forgot-password" element={<ForgotPassword setErrorMsg={setErrorMsg} />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;