import React, { useEffect, useState } from 'react';
import { ALL_VIDEOS_API, DELETE_VIDEO_API, STORY_VIDEO_API, TOKEN_KEY } from '../config/Constants';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import Loading from './Loading';

const VideoList = ({ setErrorMsg }) => {
    const [videoList, setVideoList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    setAuthToken(localStorage.getItem(TOKEN_KEY));

    const deletVideo = (videoKey) => {
        axiosInstance.delete(DELETE_VIDEO_API, { params: { videoKey: videoKey } })
            .then(resp => {
                const updatedArray = videoList.filter(item => item !== videoKey);
                setVideoList(updatedArray);
            })
            .catch(e => setErrorMsg("Failed to delete video"));
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const imageResp = await axiosInstance.get(ALL_VIDEOS_API);
            if (imageResp.status === 200) {
                setVideoList(imageResp.data);
            } else {
                setErrorMsg(imageResp.data);
            }
        } catch (error) {
            setErrorMsg("Failed to load videos");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePlayButton = async (videoKey) => {
        try {
            const videoResp = await axiosInstance.get(STORY_VIDEO_API, {params: {videoKey}});
            if (videoResp.status === 200) {
                window.open(videoResp.data);
            } else {
                setErrorMsg(videoResp.data);
            }
        } catch (error) {
            console.log(error);
            setErrorMsg("Failed to play the video");
        }
    }

    if (isLoading) return <Loading />; // Show loading indicator while fetching data

    return (
        <div className="page-container" >
            {
                videoList && videoList.map((videoKey, index) => (
                    <div key={videoKey} className="max-content my-4 cursor-pointer" onClick={() => handlePlayButton(videoKey)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10vw" height="10vh" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
                            <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"/>
                        </svg>
                        {videoKey}
                    </div>
                ))
            }            
        </div>
    );
};

export default VideoList;